// Don't judge me, this was VERY rushed to make content editing easier

import React, { ChangeEvent, useMemo, useState } from 'react'
import PageLayout from '../../components/components/page-layout'
import { Button, Control, Section } from '../../components/elements'
import { PageTitle } from '../../components/grouping'
import { AustraliaDomesticRegions, GTRRegions, Languages } from '../../types/enums'

const styles = {
  background: '#26263e',
  padding: '24px',
  marginTop: '16px',
  borderRadius: '10px',
  fontFamily: 'monospace',
  fontSize: '16px',
  color: 'white',
}

const regionObject = { ...GTRRegions, ...AustraliaDomesticRegions }

const createState = () => ({
  id: '',
  // eslint-disable-next-line unicorn/no-array-reduce
  title: Object.keys(Languages).reduce((a, k) => ({ ...a, [k]: '' }), {}),
  slug: '',
  image: '',
  regions: Object.keys(regionObject).reduce((a, k) => ({ ...a, [k]: false }), {}),
  isFlagship: false,
  flagshipAlternative: '',
})

export default function BottleCreator() {
  const [formState, setFormState] = useState(() => createState())

  const compiledJSON = useMemo(() => {
    return {
      ...formState,
      regions: Object.keys(formState.regions).reduce((a, k) => {
        return formState.regions[k] ? [...a, k] : a
      }, []),
    }
  }, [formState])

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    if (name.startsWith('title')) {
      const keyArray = name.split('.')
      const key = keyArray[keyArray.length - 1]
      setFormState({
        ...formState,
        title: {
          ...formState.title,
          [key]: value,
        },
      })

      return
    }

    if (name.startsWith('regions')) {
      const keyArray = name.split('.')
      const key = keyArray[keyArray.length - 1]
      setFormState({
        ...formState,
        regions: {
          ...formState.regions,
          [key]: !formState.regions[key],
        },
      })

      return
    }

    if (name === 'isFlagship') {
      setFormState({
        ...formState,
        isFlagship: !formState.isFlagship,
      })

      return
    }

    setFormState({
      ...formState,
      [name]: value,
    })
  }

  return (
    <PageLayout>
      <Section>
        <PageTitle alignment="center" title="Bottle Creator" />

        <div className="container">
          <div className="row flex justify-center">
            <div className="col-75">
              <form className="u__mc--bottom">
                <p>
                  <Control
                    label={{ htmlFor: 'id', children: 'ID' }}
                    input={{ name: 'id', id: 'id', value: formState.id, onChange }}
                  />
                </p>
                <p className="row">
                  <div className="col">
                    <Control
                      label={{ htmlFor: 'title.en', children: 'Title: EN' }}
                      input={{ name: 'title.en', id: 'title.en', value: formState.title.en, onChange }}
                    />
                  </div>
                  <div className="col">
                    <Control
                      label={{ htmlFor: 'title.zh_Hans', children: 'Title: zh-Hans' }}
                      input={{ name: 'title.zh_Hans', id: 'title.zh_Hans', value: formState.title.zh_Hans, onChange }}
                    />
                  </div>
                  <div className="col">
                    <Control
                      label={{ htmlFor: 'title.zh_Hant', children: 'Title: zh-Hant' }}
                      input={{ name: 'title.zh_Hant', id: 'title.zh_Hant', value: formState.title.zh_Hant, onChange }}
                    />
                  </div>
                </p>
                <p>
                  <Control
                    label={{ htmlFor: 'slug', children: 'Slug' }}
                    input={{ name: 'slug', id: 'slug', value: formState.slug, onChange }}
                  />
                </p>
                <p>
                  <Control
                    label={{ htmlFor: 'image', children: 'image' }}
                    input={{ name: 'image', id: 'image', value: formState.image, onChange }}
                  />
                </p>
                <p>
                  {Object.keys(regionObject).map((key) => (
                    <p key={key}>
                      <Control
                        label={{
                          htmlFor: `regions.${key}`,
                          children: regionObject[key],
                          style: {
                            display: 'inline',
                          },
                        }}
                        input={{
                          type: 'checkbox',
                          id: `regions.${key}`,
                          name: `regions.${key}`,
                          checked: formState.regions[key],
                          onChange,
                        }}
                      />
                    </p>
                  ))}
                </p>
                <p>
                  <Control
                    label={{
                      htmlFor: 'isFlagship',
                      children: 'Is Flagship',
                      style: {
                        display: 'inline',
                      },
                    }}
                    input={{
                      name: 'isFlagship',
                      id: 'isFlagship',
                      checked: formState.isFlagship,
                      type: 'checkbox',
                      onChange,
                    }}
                  />
                </p>
                <p>
                  <Control
                    label={{ htmlFor: 'flagshipAlternative', children: 'Flagship Alternative' }}
                    input={{
                      name: 'flagshipAlternative',
                      id: 'flagshipAlternative',
                      value: formState.flagshipAlternative,
                      onChange,
                    }}
                  />
                </p>
                <p>
                  <Button onClick={() => setFormState(createState())}>Reset</Button>
                </p>
              </form>
            </div>
          </div>
        </div>

        <Section>
          <div style={styles}>
            <pre>{JSON.stringify(compiledJSON, undefined, 2)}</pre>
          </div>
        </Section>
      </Section>
    </PageLayout>
  )
}
